/**
 * @fileoverview Toast component that provides notification functionality using
 * Syncfusion's toast module with customizable position, message, and type.
 */

import { AfterViewInit, Component, input, ViewChild } from '@angular/core';
import {
  ToastComponent as EJToast,
  ToastModule,
  ToastPositionModel,
} from '@syncfusion/ej2-angular-notifications';

/**
 * @component ToastComponent
 * @description A standalone component that displays toast notifications.
 * Uses Syncfusion's toast module for displaying notifications with configurable
 * position, message, title, and type.
 *
 * @implements {AfterViewInit}
 */
@Component({
  selector: 'hk-toast',
  standalone: true,
  imports: [ToastModule],
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss',
})
export class ToastComponent implements AfterViewInit {
  /**
   * Reference to the Syncfusion Toast element
   * @type {EJToast}
   */
  @ViewChild('element') element!: EJToast;

  /**
   * Message to be displayed in the toast notification
   * @type {string}
   * @default ''
   */
  message = input<string>('');

  /**
   * Title of the toast notification
   * @type {string}
   * @default ''
   */
  title = input<string>('');

  /**
   * Type of the toast notification
   * Controls the appearance and color scheme
   * @type {'Info' | 'Warning' | 'Error' | 'Success'}
   * @default 'Error'
   */
  type = input<'Info' | 'Warning' | 'Error' | 'Success'>('Error');

  /**
   * Position configuration for the toast notification
   * @protected
   * @type {ToastPositionModel}
   */
  protected position: ToastPositionModel = {
    X: 'Right',
    Y: 'Top',
  };

  /**
   * Lifecycle hook that is called after view initialization
   * Shows the toast notification after a brief timeout
   *
   * @implements AfterViewInit
   * @returns {void}
   */
  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.element) {
        this.element.show();
      }
    });
    return;
  }
}
